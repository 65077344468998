import React, { useState } from "react";
import downArrow from "../../assests/downArrowBlack.svg";
import upArrow from "../../assests/upArrowBlack.svg";
import styles from "./Accordian.module.css";
export default function Accordian({ summary, children, open }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen((prevState) => !prevState);
  };

  const imageSrc = isOpen ? upArrow : downArrow;

  return (
    <details onToggle={toggleOpen} open={open}>
      <summary
        className={`${styles.mainCategoty} flex justify-between items-center py-[24px] cursor-pointer sticky top-[52px] bg-white px-[16px] md:px-[12%]`}
      >
        <span
          className="whitespace-nowrap"
          style={{ fontWeight: isOpen ? 600 : 400 }}
        >
          {summary}
        </span>
        <div className="bg-black rounded-full py-[5px] px-[12px] bg-opacity-10 flex items-center gap-[8px]">
          <p className="text-small">{isOpen ? "Show Less" : "Show More"}</p>
          <img src={imageSrc} />
        </div>
      </summary>
      <div>{children}</div>
    </details>
  );
}
