import React, { useContext, useEffect, useState } from "react";
import "./Share.css";
import close from "../../assests/closebuttonblack.png";
import ServiceTerms from "./ServiceTerms";
import PurchaseFormInput from "./PurchaseFormInput";
import Context from "../../context/Context";
import styles from "./styles/PurchasePopup.module.css";
import { useUserAuth } from "../../context/UserAuthContext";
import donateIcon from "../../assests/donate.jpg";
import PopupWrapper from "../../Layout/PopupWrapper";

const PurchasePopup = ({ service, setShowPopup, promotionData }) => {
  const { currentUser } = useUserAuth();
  const {
    setGallaryClick,
    tinyUrlFromMetaData,
    campaignInfId,
    addNew,
    setAddNew,
    cartMap,
  } = useContext(Context);
  const tinyUrl =
    tinyUrlFromMetaData ||
    window.location.pathname.split("/")[1]?.replace(/\.html$/, "");
  let promotion = tinyUrl.startsWith("pro");
  const [showTerms, setShowTerms] = useState(false);
  const today = new Date();
  const yyyy = today?.getFullYear();
  const mm = String(today?.getMonth() + 1)?.padStart(2, "0"); // Months are zero-indexed
  const dd = String(today?.getDate())?.padStart(2, "0");
  const formattedDate = `${yyyy}-${mm}-${dd}`;
  const initialValues = {
    name: currentUser?.displayName || "",
    userEmail: currentUser?.email || "",
    email: service?.email,
    tc: true,
    promotion: promotion,
    shareUrl: tinyUrl,
    infId: campaignInfId,
    paymentDoc: "",
    paymentModes:
      (promotionData?.paymentModes && promotionData?.paymentModes[0]?.type) ||
      "",
    paymentMethod:
      (promotionData?.paymentModes &&
        promotionData?.paymentModes[0]?.paymentMode) ||
      "",
    offeringDate:
      promotionData?.dateMode == "CALENDAR"
        ? formattedDate
        : promotionData?.dateMode,
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [errorText, setErrorText] = useState(false);
  const [viewCart, setViewCart] = useState(false);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);
  useEffect(() => {
    const popupWrapper = document.getElementById("popup-wrapper");
    popupWrapper.style.overflow = "auto";

    return () => {
      popupWrapper.style.overflow = "unset";
    };
  }, []);
  const viewSummary = () => {
    setViewCart((prevState) => !prevState);
    const element = document.getElementById("popup-container");
    if (element) {
      element.scrollTo({
        top: 0,
        behavior: "smooth", // Adds a smooth scrolling effect
      });
    }
  };
  var coverStyle = {
    background: `linear-gradient(0deg, rgba(1, 1, 15, 0.8) 0%, rgba(1, 1, 15, 0.8) 100%), url('${promotionData?.bannerWeb}') lightgray 99% / cover no-repeat`,
  };
  const coverImage = (width) => {
    if (promotionData?.showMoreHeader?.regOpen == false) {
      return {
        background: `url('${promotionData?.bannerWeb}') no-repeat center center`,
        backgroundSize: "contain",
      };
    }
    if (width > 768) {
      return {
        background: `url('${promotionData?.bannerWeb}') no-repeat center right`,
        backgroundSize: "contain",
      };
    } else {
      return {
        background: `url('${promotionData?.bannerWeb}') no-repeat center center`,
        backgroundSize: "contain",
      };
    }
  };
  return (
    <>
      <div
        className="w-[100%] h-[100%] fixed top-0 flex flex-col items-center justify-center serviceformbg backdrop-blur-xl z-[50] overflow-x-hidden overflow-y-auto no-scrollbar"
        id="popup-container"
      >
        <div className=" absolute top-0 w-full">
          <div className="h-auto  box w-full w-full bg-[#FFFFFF] pb-[30px] mx-auto">
            <div className="w-[100%] relative  flex items-center">
              <div
                className={`w-[100%] ${
                  viewCart ? "h-auto" : "h-[220px]"
                } relative flex flex-col text-center bg-white`}
                style={coverStyle}
              >
                {!promotionData?.showMoreHeader?.directReg && (
                  <div
                    className="absolute top-2 right-2 rounded-full bg-white h-[48px] w-[48px] flex justify-center items-center hover:cursor-pointer"
                    onClick={() => {
                      setShowPopup(false);
                      setGallaryClick({});
                    }}
                  >
                    <img src={close} alt="" className="h-[16px] w-[16px] " />
                  </div>
                )}
                {viewCart ? (
                  <>
                    <PopupWrapper>
                      <button
                        className="bg-white fixed top-[16px] left-[16px] z-[98] p-[10px] rounded-[12px] flex items-center justify-center gap-[5px] text-smallest-bold text-black drop-shadow-md"
                        onClick={viewSummary}
                      >
                        <svg
                          width="10"
                          height="16"
                          viewBox="0 0 10 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9 15L1 8L9 0.999999"
                            stroke="url(#paint0_linear_24_999)"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <defs>
                            <linearGradient
                              id="paint0_linear_24_999"
                              x1="1.62501"
                              y1="1"
                              x2="39.125"
                              y2="0.999996"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stop-color="black" />
                              <stop offset="1" stop-color="black" />
                            </linearGradient>
                          </defs>
                        </svg>
                        Back
                      </button>
                      {/* <div
                        className={` w-full fixed top-0 z-[98] py-[12px] px-[20px] flex items-center justify-between gap-[16px] bg-[#fff]`}
                      >
                        <button
                          className="bg-white fixed top-0 z-[98] p-[10px] rounded-[12px] flex items-center justify-center gap-[5px] text-smallest-bold text-black drop-shadow-md"
                          onClick={viewSummary}
                        >
                          <svg
                            width="10"
                            height="16"
                            viewBox="0 0 10 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9 15L1 8L9 0.999999"
                              stroke="url(#paint0_linear_24_999)"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <defs>
                              <linearGradient
                                id="paint0_linear_24_999"
                                x1="1.62501"
                                y1="1"
                                x2="39.125"
                                y2="0.999996"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop stop-color="black" />
                                <stop offset="1" stop-color="black" />
                              </linearGradient>
                            </defs>
                          </svg>
                          Back
                        </button>
                        <div className="flex items-center justify-end">
                          {promotionData?.otherOfferings?.map((obj, index) => {
                            if (obj?.productId == "hundial") {
                              return (
                                <button
                                  className="bg-white p-[10px] rounded-[12px] flex items-center justify-center gap-[5px] text-smallest-bold text-black drop-shadow-md"
                                  onClick={() => {
                                    if (
                                      cartMap.get(obj?.productId)?.count > 0
                                    ) {
                                      setErrorText("Kanikka Already added ");
                                    } else {
                                      setAddNew({ display: true, value: obj });
                                    }
                                  }}
                                >
                                  <img
                                    src={donateIcon}
                                    className="w-[20px] h-[20px] rounded-[2px]"
                                  />
                                  കാണിക്ക സമർപ്പിക്കുക
                                </button>
                              );
                            }
                          })}
                        </div>
                      </div> */}
                    </PopupWrapper>

                    {/* <div className="h-[64px]" /> */}
                  </>
                ) : (
                  <div className="flex-1">
                    <div className="flex justify-center px-[16px] py-[8px] h-full  gap-[10px] md:gap-[40px]">
                      <div
                        className="w-[50%] h-full "
                        style={coverImage(window.innerWidth)}
                      />
                      {promotionData?.showMoreHeader?.regOpen !== false && (
                        <div className="w-[50%] flex flex-col justify-center gap-[10px]">
                          {promotionData?.otherOfferings?.map((obj, index) => {
                            if (obj?.productId == "hundial") {
                              return (
                                <button
                                  className="bg-white p-[10px] rounded-[12px] flex items-center justify-center gap-[5px] text-smallest-bold text-black drop-shadow-md max-w-[250px]"
                                  onClick={() => {
                                    if (
                                      cartMap.get(obj?.productId)?.count > 0
                                    ) {
                                      setErrorText("Kanikka Already added ");
                                    } else {
                                      setAddNew({ display: true, value: obj });
                                    }
                                  }}
                                >
                                  <img
                                    src={donateIcon}
                                    className="w-[20px] h-[20px] rounded-[2px]"
                                  />
                                  കാണിക്ക സമർപ്പിക്കുക
                                </button>
                              );
                            }
                          })}
                          <button
                            className="relative bg-white p-[10px] rounded-[12px]  flex items-center justify-center gap-[5px] text-smallest-bold text-black drop-shadow-md max-w-[250px]"
                            onClick={() => {
                              formValues?.totalCount > 0
                                ? viewSummary()
                                : setErrorText(
                                    "Please add a vazhipadu to view cart"
                                  );
                            }}
                          >
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M14.1663 15.0003C13.2413 15.0003 12.4997 15.742 12.4997 16.667C12.4997 17.109 12.6753 17.5329 12.9878 17.8455C13.3004 18.1581 13.7243 18.3337 14.1663 18.3337C14.6084 18.3337 15.0323 18.1581 15.3449 17.8455C15.6574 17.5329 15.833 17.109 15.833 16.667C15.833 16.225 15.6574 15.801 15.3449 15.4885C15.0323 15.1759 14.6084 15.0003 14.1663 15.0003ZM0.833008 1.66699V3.33366H2.49967L5.49967 9.65866L4.36634 11.7003C4.24134 11.9337 4.16634 12.2087 4.16634 12.5003C4.16634 12.9424 4.34194 13.3663 4.6545 13.6788C4.96706 13.9914 5.39098 14.167 5.83301 14.167H15.833V12.5003H6.18301C6.12775 12.5003 6.07476 12.4784 6.03569 12.4393C5.99662 12.4002 5.97467 12.3472 5.97467 12.292C5.97467 12.2503 5.98301 12.217 5.99967 12.192L6.74967 10.8337H12.958C13.583 10.8337 14.133 10.4837 14.4163 9.97533L17.3997 4.58366C17.458 4.45033 17.4997 4.30866 17.4997 4.16699C17.4997 3.94598 17.4119 3.73402 17.2556 3.57774C17.0993 3.42146 16.8874 3.33366 16.6663 3.33366H4.34134L3.55801 1.66699M5.83301 15.0003C4.90801 15.0003 4.16634 15.742 4.16634 16.667C4.16634 17.109 4.34194 17.5329 4.6545 17.8455C4.96706 18.1581 5.39098 18.3337 5.83301 18.3337C6.27504 18.3337 6.69896 18.1581 7.01152 17.8455C7.32408 17.5329 7.49967 17.109 7.49967 16.667C7.49967 16.225 7.32408 15.801 7.01152 15.4885C6.69896 15.1759 6.27504 15.0003 5.83301 15.0003Z"
                                fill="#000"
                              />
                            </svg>
                            {`വാഴിപാടുകൾ ( ${formValues?.totalCount} )`}
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <div
                  className="w-full bg-[#2c3892] h-fit p-[16px]"
                  style={viewCart ? coverStyle : {}}
                >
                  <h2 className={`${styles.heading2} ${styles.malayalam}`}>
                    {promotionData?.eventHeader?.eventName}
                  </h2>
                  <h3 className={styles.subHeading2}>
                    {promotionData?.eventHeader?.location}
                  </h3>
                </div>
              </div>
            </div>
            <div className="max-w-[800px] w-full mx-auto">
              {promotionData?.showMoreHeader?.regOpen == false ? (
                <>
                  <pre className="leadcardtext text-black text-center py-[50px] px-[20px]">
                    {promotionData?.showMoreHeader?.regText || "Will open soon"}
                  </pre>
                  <div className="h-[70vh]" />
                </>
              ) : (
                <PurchaseFormInput
                  setShowPopup={setShowPopup}
                  setShowTerms={setShowTerms}
                  promotionData={promotionData}
                  service={service}
                  formValues={formValues}
                  setFormValues={setFormValues}
                  errorText={errorText}
                  setErrorText={setErrorText}
                  viewCart={viewCart}
                  setViewCart={setViewCart}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {showTerms && <ServiceTerms setShowTerms={setShowTerms} />}
    </>
  );
};

export default PurchasePopup;
