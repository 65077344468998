import React from "react";
import styles from "./Styles/CompletionBanner.module.css";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
import PopupWrapper from "../../Layout/PopupWrapper";

export default function CompletionBanner({
  promotionData,
  price,
  count,
  summaryRef,
  hideCart,
  setViewCart,
}) {
  const hostname = window.location.hostname;
  const parts = hostname?.split(".");
  const subDomain =
    parts?.length > 2 ? parts?.slice(0, parts?.length - 2).join(".") : null;
  const collection = subDomain == "collection";

  const viewSummary = () => {
    setViewCart(true);
    const element = document.getElementById("popup-container");
    if (element) {
      element.scrollTo({
        top: 0,
        behavior: "smooth", // Adds a smooth scrolling effect
      });
    }
    // summaryRef.current.scrollIntoView({
    //   behavior: "smooth",
    //   block: "start",
    //   inline: "start",
    // });
  };

  const buttonColor = () => {
    return {
      background: promotionData?.btnColor,
      color: promotionData?.btnTextColor,
    };
  };

  const secondaryButton = () => {
    return {
      border: `1px solid ${promotionData?.btnColor}`,
      color: promotionData?.btnColor,
      background: promotionData?.btnTextColor,
    };
  };

  return (
    <PopupWrapper>
      {count > 0 && !hideCart && (
        <div className={styles.background}>
          <div className={styles.innerContainer}>
            <div className="">
              <h4>
                {count} Item{count > 1 && "s"} added{" "}
              </h4>
              <p className="mt-[4px]">Total cost: ₹{price}</p>
            </div>
            <button onClick={viewSummary}>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.1663 15.0003C13.2413 15.0003 12.4997 15.742 12.4997 16.667C12.4997 17.109 12.6753 17.5329 12.9878 17.8455C13.3004 18.1581 13.7243 18.3337 14.1663 18.3337C14.6084 18.3337 15.0323 18.1581 15.3449 17.8455C15.6574 17.5329 15.833 17.109 15.833 16.667C15.833 16.225 15.6574 15.801 15.3449 15.4885C15.0323 15.1759 14.6084 15.0003 14.1663 15.0003ZM0.833008 1.66699V3.33366H2.49967L5.49967 9.65866L4.36634 11.7003C4.24134 11.9337 4.16634 12.2087 4.16634 12.5003C4.16634 12.9424 4.34194 13.3663 4.6545 13.6788C4.96706 13.9914 5.39098 14.167 5.83301 14.167H15.833V12.5003H6.18301C6.12775 12.5003 6.07476 12.4784 6.03569 12.4393C5.99662 12.4002 5.97467 12.3472 5.97467 12.292C5.97467 12.2503 5.98301 12.217 5.99967 12.192L6.74967 10.8337H12.958C13.583 10.8337 14.133 10.4837 14.4163 9.97533L17.3997 4.58366C17.458 4.45033 17.4997 4.30866 17.4997 4.16699C17.4997 3.94598 17.4119 3.73402 17.2556 3.57774C17.0993 3.42146 16.8874 3.33366 16.6663 3.33366H4.34134L3.55801 1.66699M5.83301 15.0003C4.90801 15.0003 4.16634 15.742 4.16634 16.667C4.16634 17.109 4.34194 17.5329 4.6545 17.8455C4.96706 18.1581 5.39098 18.3337 5.83301 18.3337C6.27504 18.3337 6.69896 18.1581 7.01152 17.8455C7.32408 17.5329 7.49967 17.109 7.49967 16.667C7.49967 16.225 7.32408 15.801 7.01152 15.4885C6.69896 15.1759 6.27504 15.0003 5.83301 15.0003Z"
                  fill="white"
                />
              </svg>
              View Cart
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M9.4 18L8 16.6L12.6 12L8 7.4L9.4 6L15.4 12L9.4 18Z"
                  fill="white"
                />
              </svg>
            </button>
            {/* <div className="flex items-center gap-[5px]">
              {collection && (
                <button
                  onClick={() => onClick("COLLECTION")}
                  disabled={loading}
                  style={secondaryButton()}
                >
                  {loading ? <LoadingCircle /> : "Pay Later"}
                </button>
              )}
              <button
                onClick={() => onClick("NORMAL")}
                disabled={loading}
                style={buttonColor()}
              >
                {loading ? <LoadingCircle /> : "Pay and Submit"}
              </button>
            </div> */}
          </div>
        </div>
      )}
    </PopupWrapper>
  );
}
