import React, { useEffect } from "react";
import styles from "./WhatsappError.module.css";
import PopupWrapper from "../../Layout/PopupWrapper";
import prodBg from "../../assests/prodBg.png";

const WhatsappError = ({
  logo,
  setPopup,
  heading,
  message,
  numberObj,
  setNumberObj,
  formErrors,
  handleSubmit,
}) => {
  // useEffect(() => {
  //   document.body.style.overflow = "hidden";
  //   return () => {
  //     document.body.style.overflow = "unset";
  //   };
  // }, []);
  const prodCover = {
    backgroundImage: `url(${prodBg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  };

  return (
    <PopupWrapper>
      <div className={styles.mainDiv}>
        <div className={styles.container}>
          <div className={styles.header} style={prodCover}>
            <h2 className="malayalam"></h2>
            <button
              onClick={() => {
                setPopup(false);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M18 6L6 18M6 6L18 18"
                  stroke="black"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </div>
          <div className="px-[16px] pb-[30px] pt-[16px]">
            {/* <img src={logo} alt="" className="w-[56px] h-[56px] mb-[24px]  " /> */}
            <p className="text-regular-medium mb-[5px]">{heading}</p>
            <pre className="text-small">{message}</pre>
            <div className="seperator-black my-[20px]" />
            <div className="mb-[25px]">
              <div
                className={`flex items-center relative w-full mt-[10px] ${
                  formErrors.phone ? styles.errorOutline : ""
                }`}
              >
                <div className={styles.phoneInput}>
                  <input
                    type="text"
                    id="countryCode"
                    name="countryCode"
                    maxlength="4"
                    className="text-regular-callout"
                    // onChange={handleCountryChange}
                    // onClick={toggleOpen}
                    autoComplete="off"
                    value={numberObj?.countryCode}
                    readOnly
                  />
                  <input
                    className={`w-[100%] px-[16px] py-[15px] text-[14px]
                ${styles.placeholder} 
                `}
                    type="text"
                    placeholder="Enter WhatsApp Number"
                    id="phone"
                    value={numberObj?.number}
                    onChange={(e) =>
                      setNumberObj((prevState) => ({
                        ...prevState,
                        number: e.target.value,
                      }))
                    }
                  />
                </div>
              </div>
              {formErrors.phone && (
                <span className={styles.error}>
                  <span className="text-red-600">*</span>
                  {formErrors.phone}
                </span>
              )}
            </div>
            <div className="flex flex-col gap-[16px] w-[100%] cursor-pointer">
              <button
                className={`text-regular-medium ${styles.button}`}
                onClick={handleSubmit}
              >
                Proceed to Payment
              </button>
            </div>
          </div>
        </div>
      </div>
    </PopupWrapper>
  );
};

export default WhatsappError;
