import * as _ from "lodash";
import "./Share.css";
import DefaultTemplate from "../PurchaseFormTemplates/DefaultTemplate";

const PurchaseFormInput = ({
  setShowPopup,
  setShowTerms,
  promotionData,
  service,
  formValues,
  setFormValues,
  errorText,
  setErrorText,
  viewCart,
  setViewCart,
}) => {
  let type = promotionData?.templateType;
  let pageJXS;

  switch (type) {
    default:
      pageJXS = (
        <DefaultTemplate
          setShowTerms={setShowTerms}
          promotionData={promotionData}
          setServiceForm={setShowPopup}
          service={service}
          formValues={formValues}
          setFormValues={setFormValues}
          errorText={errorText}
          setErrorText={setErrorText}
          viewCart={viewCart}
          setViewCart={setViewCart}
        />
      );
  }
  return <>{pageJXS}</>;
};
export default PurchaseFormInput;
