import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useUserAuth } from "../context/UserAuthContext";
import Context from "../context/Context";
import styles from "./styles/RegistrationListingPage.module.css";
import LoadingCircle from "../Components/LoadingCircle/LoadingCircle";
import SignupPage from "./SignupPage";
import {
  Link,
  createSearchParams,
  useParams,
  useSearchParams,
} from "react-router-dom";
import user from "../assests/user.svg";
import { isEmpty } from "lodash";
import ProfileDropDown from "../Components/ProfileDropDown/ProfileDropDown";
import PurchaseList from "../Components/PurchaseList/PurchaseList";
import searchIcon from "../assests/searchIcon.svg";
let timer;

export default function PurchaseListingPage() {
  const { userIdToken, update, tinyUrlFromMetaData } = useContext(Context);
  const { tinyUrl } = useParams();
  const tinyUrlFromUrl =
    tinyUrlFromMetaData ||
    window.location.pathname.split("/")[1]?.replace(/\.html$/, "");
  const organizationId = tinyUrlFromUrl.split("o").pop().split("ent")[0];
  const entryId = tinyUrlFromUrl.split("ent").pop().split("inf")[0];
  const [showProfileDropdown, setShowProfileDropdown] = useState(false);
  const [loading, setLoading] = useState(false);
  const { currentUser, setPreviousLocation, setShowLogin, showLogin } =
    useUserAuth();
  const token = userIdToken || currentUser?.accessToken;
  const [registrationDetails, setRegistrationDetails] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [numberObj, setNumberObj] = useState({
    countryCode: "+91",
    number: null,
  });
  const [debouncedTerm, setDebouncedTerm] = useState("");

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedTerm(numberObj?.number);
    }, 1000); //

    return () => clearTimeout(timer); // Cleanup the timer on input change
  }, [numberObj?.number]);
  useEffect(() => {
    if (debouncedTerm) {
      loadRegistationDetails(); // Call your API function
    }
  }, [debouncedTerm]);
  // useEffect(() => {
  //   if (currentUser) {
  //     loadRegistationDetails();
  //   } else {
  //     setPreviousLocation(window.location.pathname);
  //     setShowLogin(true);
  //   }
  // }, [, update]);

  const loadRegistationDetails = async () => {
    try {
      setLoading(true);
      const phoneNumber = (numberObj?.countryCode + numberObj?.number)?.replace(
        /\s+/g,
        ""
      );
      let config = {
        method: "POST",
        url: `${process.env.REACT_APP_GET_PURSHASE_BY_NUMBER}/${organizationId}/${entryId}`,
        data: { phoneNumber },
      };
      const response = await axios(config);
      setRegistrationDetails(response?.data?.data);
      setLoading(false);
    } catch (err) {
      console.log(err.message);
      setLoading(false);
    }
  };
  const handleProfileClick = () => {
    if (currentUser) {
      setShowProfileDropdown((prevState) => !prevState);
    } else {
      setShowLogin(true);
    }
  };

  return (
    <div className="bg-white">
      {showLogin && <SignupPage />}

      <div className={styles.mainDiv}>
        <div className="w-full max-w-[800px] mx-auto">
          <div className="flex justify-between items-center w-full sticky top-[20px] mb-[20px] z-[10]">
            <Link
              to={{
                pathname: `/${tinyUrl?.replace(/\.html$/, "")}.html`,
                // search: `${
                //   searchParams ? createSearchParams(searchParams) : ""
                // }`,
              }}
              className="w-full "
            >
              <button className="px-[25px] py-[10px] flex items-center gap-[5px] bg-[#fff] rounded-full mr-auto">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                >
                  <path
                    d="M6.47173 3.02843C6.50976 3.06643 6.53994 3.11155 6.56052 3.16121C6.58111 3.21087 6.59171 3.2641 6.59171 3.31787C6.59171 3.37163 6.58111 3.42486 6.56052 3.47452C6.53994 3.52419 6.50976 3.5693 6.47173 3.6073L3.48792 6.59059L11.5005 6.59059C11.609 6.59059 11.713 6.63369 11.7897 6.71041C11.8665 6.78713 11.9096 6.89119 11.9096 6.99968C11.9096 7.10818 11.8665 7.21224 11.7897 7.28895C11.713 7.36567 11.609 7.40877 11.5005 7.40877H3.48792L6.47173 10.3921C6.54849 10.4688 6.59161 10.5729 6.59161 10.6815C6.59161 10.7901 6.54849 10.8942 6.47173 10.9709C6.39497 11.0477 6.29085 11.0908 6.1823 11.0908C6.07374 11.0908 5.96963 11.0477 5.89286 10.9709L2.21105 7.28912C2.17301 7.25112 2.14284 7.206 2.12225 7.15634C2.10166 7.10668 2.09106 7.05344 2.09106 6.99968C2.09106 6.94592 2.10166 6.89269 2.12225 6.84303C2.14284 6.79336 2.17301 6.74825 2.21105 6.71025L5.89286 3.02843C5.93086 2.9904 5.97598 2.96022 6.02564 2.93964C6.0753 2.91905 6.12853 2.90845 6.1823 2.90845C6.23606 2.90845 6.28929 2.91905 6.33895 2.93964C6.38862 2.96022 6.43373 2.9904 6.47173 3.02843Z"
                    fill="#000"
                  />
                </svg>
                <p className="text-small-bold text-[#000]">Back</p>
              </button>
            </Link>
            {/* <div
                className=" items-center gap-[10px] flex self-end relative py-[6px] px-[10px] transition duration-300 ease-in"
                onClick={handleProfileClick}
                style={{
                  background: showProfileDropdown ? " " : "",
                  color: showProfileDropdown ? "#F6F6F6" : "#F6F6F6",
                }}
              >
                <div className="flex md:h-[52px] md:w-[52px] min-w-[44px] md:min-w-[52px]  h-[44px] w-[44px] bg-blue-400  items-center justify-center rounded-full">
                  <img
                    src={currentUser?.photoURL ? currentUser?.photoURL : user}
                    alt=""
                    className="h-full w-full object-cover rounded-full overflow-hidden cursor-pointer z-10"
                  />
                </div>
                {showProfileDropdown && <ProfileDropDown />}
              </div> */}
          </div>
          <h1 className="text-white text-large-bold mb-[25px]">Bookings</h1>
          <div className="flex flex-col justify-center items-center w-full bg-[#ffffff] rounded-[12px] px-[10px] my-[20px]">
            <div className={`w-full my-[10px]`}>
              <p className="text-left text-black text-small-bold mb-[8px]">
                Search Booking using phone number
              </p>
              <div className={styles.phoneInput}>
                <input
                  type="text"
                  id="countryCode"
                  name="countryCode"
                  maxlength="4"
                  className="text-regular-callout"
                  // onChange={handleCountryChange}
                  // onClick={toggleOpen}
                  autoComplete="off"
                  value={numberObj?.countryCode}
                  readOnly
                />
                <input
                  className={`w-[100%] px-[8px] py-[15px] text-[14px] ${styles.placeholder}`}
                  type="text"
                  placeholder="Search using phone number"
                  id="phone"
                  value={numberObj?.phone}
                  onChange={(e) =>
                    setNumberObj((prevState) => ({
                      ...prevState,
                      number: e.target.value,
                    }))
                  }
                />
              </div>
            </div>
          </div>
          {loading ? (
            <div className="flex flex-col gap-[10px] justify-center items-center h-[20vh] w-full bg-[#ffffff] rounded-[12px] p-[20px]">
              <div className="w-[25px] h-[25px] ">
                <LoadingCircle />
              </div>
              <p className="text-black text-center text-regular-medium">
                Fetching Details
              </p>
            </div>
          ) : isEmpty(registrationDetails) ? (
            <div className="flex flex-col justify-center items-center h-[30vh] w-full bg-[#ffffff] rounded-[12px] px-[20px]">
              <p className="text-regular-medium text-black">No Bookings</p>
            </div>
          ) : (
            !loading &&
            registrationDetails?.map((obj) => <PurchaseList details={obj} />)
          )}
        </div>
      </div>
    </div>
  );
}
