import React from "react";
import styles from "./PurchaseList.module.css";
import { Link, createSearchParams, useSearchParams } from "react-router-dom";
import regSuccess from "../../assests/regSuccess.svg";
import regFailed from "../../assests/regFailed.svg";

export default function PurchaseList({ details }) {
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <Link
      to={{
        pathname: details?.orderId,
        search: `${searchParams ? createSearchParams(searchParams) : ""}`,
      }}
    >
      <div className={styles.container}>
        <div className="flex w-full gap-[15px]">
          <table className={styles.table}>
            <tr>
              <td className="text-small-bold whitespace-nowrap">Order Id</td>
              <td className="text-small break-all">{details?.orderId}</td>
            </tr>
            {details?.createdAt && (
              <tr>
                <td className="text-small-bold whitespace-nowrap">
                  Order date & time
                </td>
                <td className="text-small break-all">
                  {(() => {
                    const dateTime = new Date(details.createdAt);
                    const date = dateTime.toDateString();
                    const time = dateTime.toTimeString().split(" ")[0];
                    return `${date} ${time}`;
                  })()}
                </td>
              </tr>
            )}
            {details?.totalAmt && (
              <tr>
                <td className="text-small-bold whitespace-nowrap">
                  Total Amount
                </td>
                <td className="text-small break-all">₹ {details?.totalAmt}</td>
              </tr>
            )}
            {details?.paymentStatus && (
              <tr>
                <td className="text-small-bold whitespace-nowrap">
                  Payment Status
                </td>
                <td className="text-small justify-end flex items-center gap-[5px]">
                  {details?.paymentStatus == "PAYMENT_SUCCESS" ||
                  details?.paymentStatus == "FREE_ENTRY"
                    ? "Success"
                    : "Failed"}
                  <img
                    src={
                      details?.paymentStatus == "PAYMENT_SUCCESS" ||
                      details?.paymentStatus == "FREE_ENTRY"
                        ? regSuccess
                        : regFailed
                    }
                    alt=""
                    style={{ width: "15px", height: "15px" }}
                  />
                </td>
              </tr>
            )}
          </table>
        </div>
        <button className="whitespace-nowrap">View Booking</button>
      </div>
    </Link>
  );
}
