import React, { useEffect } from "react";
import styles from "./SubmitFormPopup.module.css";
import PopupWrapper from "../../Layout/PopupWrapper";

const SubmitFormPopup = ({
  logo,
  heading,
  setPopup,
  message,
  buttonText,
  handleSubmit,
  loading,
}) => {
  // useEffect(() => {
  //   document.body.style.overflow = "hidden";
  //   return () => {
  //     document.body.style.overflow = "unset";
  //   };
  // }, []);
  return (
    <PopupWrapper>
      <div className={styles.mainDiv}>
        <div className={styles.container}>
          {/* <img src={logo} alt="" className="w-[56px] h-[56px] mb-[24px]  " /> */}
          <p className="text-regular-medium px-[20px] mb-[16px]">{heading}</p>
          {message && (
            <pre className="text-small text-[#000] px-[20px]">{message}</pre>
          )}
          <div className="seperator-black my-[20px]" />
          <div className="flex flex-col gap-[16px] w-[100%] cursor-pointer">
            <button
              className={`text-regular-medium ${styles.button}`}
              onClick={handleSubmit}
            >
              {loading ? "Submiting.." : buttonText}
            </button>
            <button
              className={`text-regular-medium ${styles.cancel}`}
              onClick={() => setPopup(false)}
              disabled={loading}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </PopupWrapper>
  );
};

export default SubmitFormPopup;
